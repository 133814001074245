<template>
  <div class="contain">
    <div class="title">限时拍卖规则</div>
    <div class="page">
      <div class="mb-12">
        <div class="mb-6">1. 起拍价</div>
        <div class="mb-8">
          该拍卖商品的最低价格，低于此金额的出价将不被接受。
        </div>
      </div>
      <div class="mb-12">
        <div class="mb-6">2. 保底机制</div>
        <div class="mb-8">如果设置保底价：成交价高于保底价时，拍卖成交；</div>
        <div class="mb-8">
          如果不设置保底价：拍卖期结束后，最高的出价将被接受，该竞拍者获得拍品。
        </div>
      </div>
      <div class="mb-12">
        <div class="mb-6">3. 立即购买机制</div>
        <div class="mb-8">
          当出价超过一口价时，拍品以一口价成交，拍卖将会立即结束。
        </div>
      </div>
      <div class="mb-12">
        <div class="mb-6">4. 撤回机制</div>
        <div class="mb-8">
          您可以在收到有效出价之前撤回您的拍卖品；有效出价后无法取消拍卖。
        </div>
      </div>
      <div class="mb-12">
        <div class="mb-6">5. 出价机制</div>
        <div class="mb-8">
         您的出价需要比当前出价至少高出5%，并且需要支付相应金额。一旦出现更高的出价，您支付的金额将被退回。
        </div>
      </div>
      <div class="mb-12">
        <div class="mb-6">6. 加时机制</div>
        <div class="mb-8">
          在拍卖期最后10分钟内进行的任何出价都会将拍卖延长10分钟。
        </div>
      </div>
      <div class="mb-12">
        <div class="mb-6">7. 流拍机制</div>
        <div class="mb-8">
          拍卖期结束后，任何未收到任何投标的NFT将被视为未售出。
        </div>
      </div>
      <div class="mb-12">
        <div class="mb-6">8. 拍卖成交机制</div>
        <div class="mb-1">拍卖期结束后，平台将进行以下操作：</div>
        <div class="mb-1">
          ·设置了保底价，且高于保底价：平台执行“成交”操作；
        </div>
        <div class="mb-1">
          ·设置了保底价，但低于保底价：平台执行“放弃”操作；
        </div>
        <div class="mb-8">·未设置保底价：平台执行“成交”操作。</div>
      </div>
      <div class="mb-12">
        <div class="mb-6">9. 拍卖服务费</div>
        <div class="mb-8">
          拍卖成交金额的10%作为平台服务费以维持平台的正常运转。
        </div>
      </div>
    </div>
    <div class="title">常见问题</div>
    <div class="page">
      <div class="mb-12">
        <div class="mb-6">1. 如何发起限时拍卖？</div>
        <div class="mb-8">
          当您将您的物品进行定时拍卖时，您需要设置拍卖的起拍价、开始时间和截止日期。并且您还可以选择设置保底价和一口价。
        </div>
        <div class="mb-1">· 起拍价：低于此金额的出价将不被接受。</div>
        <div class="mb-1">· 保底价：拍卖结束后，高于保底价的出价将被接受。</div>
        <div class="mb-8">
          · 一口价：当出价超过一口价时，拍品以一口价成交，拍卖将会立即结束。
        </div>
        <div class="mb-1">请注意：</div>
        <div class="mb-1">· 一旦有有效出价，限时拍卖便不能取消。</div>
        <div class="mb-8">
          · 在最后10分钟内进行的任何出价都会将拍卖延长10分钟。
        </div>
      </div>
      <div class="mb-12">
        <div class="mb-6">2. 如何对拍品进行出价？</div>
        <div class="mb-1">如果您想出价，您需要满足以下条件：</div>
        <div class="mb-1">· 高于起拍价</div>
        <div class="mb-8">· 至少为当前最高有效出价的105%</div>
      </div>
      <div class="mb-12"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.eventBus.$on("LangChange", this.onLangChange);
  },
  beforeDestroy() {
    window.eventBus.$off("LangChange", this.onLangChange);
  },
  methods: {
    onLangChange() {
      if (this.$vuetify.lang.current == "en") {
        this.$router.push({
          name: "Auctionruleen",
        });
      } else if (this.$vuetify.lang.current == "zh") {
        this.$router.push({
          name: "Auctionrulezh",
        });
      } else if (this.$vuetify.lang.current == "ja") {
        this.$router.push({
          name: "Auctionruleen",
        });
      } else if (this.$vuetify.lang.current == "ko") {
        this.$router.push({
          name: "Auctionruleen",
        });
      } else if (this.$vuetify.lang.current == "pt") {
        this.$router.push({
          name: "Auctionruleen",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  max-width: 856px;
  margin: 0 auto;

  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 55px !important;
    font-weight: bold;
    line-height: 60px;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #270645;
    text-align: center;
  }

  .page {
    .mb-12 {
      .mb-6 {
        font-weight: bold;
        font-size: 30px;
        font-weight: bold !important;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }

      .mb-8,
      .mb-1 {
        font-family: Helvetica;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
      }
    }
  }
}
</style>
